import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';

import PopupIcon1 from '../../images/features/email-popups/full-screen.gif'
import PopupIcon2 from '../../images/features/email-popups/floating-bars.gif'
import PopupIcon3 from '../../images/features/email-popups/Spin-the-Wheel-Popups.gif'
import PopupIcon4 from '../../images/features/email-popups/embed.gif'
import PopupIcon5 from '../../images/features/email-popups/timer.gif'
import PopupIcon6 from '../../images/features/email-popups/Slide-in-Popup.gif'

const HomePopupTypes = () => {
    const [activeTab, setActiveTab] = useState('1')

    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    return (
        <div className="email-popup-types-section py-5">
            <div className="container">
                <div className="hero-content wow fadeIn text-center mb-5">
                    <h2 className="my-5">Powerful Features to Create Highly Converting <span className="style-highlight"> Email Pop-ups
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                    <h3 className="font-weight-normal"><span className="style-highlight"> Different Types
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span> of Email Pop-ups </h3>
                    <p>Different types of popups for different marketing scenarios. Explore the multiple types of popups Optinly has – <br/> grow your marketing list and business the most unique way possible.</p>
                </div>
                <div className="vertical-tab-container container mt-5">                    
                    <div className="tab">
                        <Nav tabs>
                            <NavItem>
                                <NavLink onClick={() => { toggle('1'); }} className={( activeTab === '1') ? ' active' : ''}>
                                    Fullscreen Overlay Popups
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink  onClick={() => { toggle('2'); }} className={( activeTab === '2') ? ' active' : ''}>
                                    Floating Bars
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink  onClick={() => { toggle('3'); }} className={( activeTab === '3') ? 'active' : ''}>
                                    Spin the Wheel Popups​
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink  onClick={() => { toggle('4'); }} className={( activeTab === '4') ? 'active' : ''}>
                                    Embed Popups​
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink  onClick={() => { toggle('5'); }} className={( activeTab === '5') ? 'active' : ''}>
                                    Countdown Timer
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink  onClick={() => { toggle('6'); }} className={( activeTab === '6') ? 'active' : ''}>
                                    Slide-in Popup
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <div className="text-center">
                                    <img src={PopupIcon1} alt="" />
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div className="text-center">
                                    <img src={PopupIcon2} alt="" />
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div className="text-center">
                                    <img src={PopupIcon3} alt="" />
                                </div>
                            </TabPane>
                            <TabPane tabId="4">
                                <div className="text-center">
                                    <img src={PopupIcon4} alt="" />
                                </div>
                            </TabPane>
                            <TabPane tabId="5">
                                <div className="text-center">
                                    <img src={PopupIcon5} alt="" />
                                </div>
                            </TabPane>
                            <TabPane tabId="6">
                                <div className="text-center">
                                    <img src={PopupIcon6} alt="" />
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePopupTypes