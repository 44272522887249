import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { FaArrowRight } from 'react-icons/fa';

import Joshual from '../../images/joshual.png'

import FeatureIcon1 from '../../images/features/email-popups/Exit-Intent-Triggers.gif'
import FeatureIcon2 from '../../images/features/email-popups/URL-Based-Triggers.gif'
import FeatureIcon3 from '../../images/features/email-popups/Time-Delayed-Triggers.gif'
import FeatureIcon4 from '../../images/features/email-popups/device-based-triggers.gif'
import FeatureIcon5 from '../../images/features/email-popups/on-click-trigger.gif'
import FeatureIcon6 from '../../images/features/email-popups/popup-display-position.gif'

class HomePopupTriggerRules extends Component {
    render() {
        return (
            <div className="email-popup-trigger-rules-section py-5">
                <div className="container">
                    <div className="hero-content wow fadeIn text-center mb-5">
                        <h2>Advanced <span className="style-highlight"> Email Popup
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span> Triggering Rules </h2>
                        <p>Display email collection popups to web visitors when it matters the most. Triggering email popups at the right time constitutes to half the conversions. Grab attention and capture leads as you like using Optinly’s multiple popup display rules.</p>
                    </div>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4 pe-5">
                                <h3>Exit Intent Triggers</h3>
                                <p>Stop visitors from abandoning your webpage/eCommerce store. Display engaging email popups by detecting user behavior – trigger popups when they try to exit.</p>
                                <div className="testimonial-block">
                                    <div className="elementor-testimonial">
                                        <div className="elementor-testimonial__content">
                                            <div className="elementor-testimonial__text">
                                                Pretty awesome tool. I've was on the lookout for a tool that does exit intent popups/slideins and Optinly came along. Their webapp is clean and fast. Support was crazy fast to reply to my questions too! </div>
                                            <cite className="elementor-testimonial__cite"><span className="elementor-testimonial__name">- Joshua.L</span></cite> </div>
                                        <div className="elementor-testimonial__footer">
                                            <div className="elementor-testimonial__image">
                                                <img src={Joshual} alt="- Joshua.L" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon1} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon2} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5">
                                <h3>URL Based Triggers</h3>
                                <p>Choose what would be the best pages to run popup campaigns. Show email collection popups on all pages or only on selected pages by using multiple URL-based conditions.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center pt-5">
                        <Col md="6">
                            <div className="mb-4 pe-5">
                                <h3>Time-Delayed Triggers</h3>
                                <p>Let your visitors settle in before you display email collection popups. Use time-delayed triggers and manually delay popup display.</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon3} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon4} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5">
                                <h3>Device-Based Triggers</h3>
                                <p>Display email popups on all kinds of devices – mobiles/desktops/tablets. Use toggle switches to choose between device combinations.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center pt-5">
                        <Col md="6">
                            <div className="mb-4 pe-5">
                                <h3>On-click Triggers</h3>
                                <p>Make popups more relevant to the web audience. Display popups when users click on specific links on your webpage.</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon5} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon6} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5">
                                <h3>Popup Display Positions</h3>
                                <p>Optinly email popup tool allows you to display popups on different positions – slide-in popups on the left/right of your screen and floating bars at the top/bottom of your screen.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default HomePopupTriggerRules
