import React from 'react'
import Layout from '../../components/layout'
import Metatags from "../../components/Metatags"
//import home page components
import GetStarted from '../../components/getstarted'
import Banner from '../../components/Banner';
import HomeIntegrationCompanies from '../../constants/Home/IntegrationCompanies';
import HomeCounter from '../../constants/Home/homeCounter';
import HomePopupFeatures from '../../constants/EmailPopups/HomePopupFeatures';
import HomePopupTypes from '../../constants/EmailPopups/HomePopupTypes';
import HomePopupTriggerRules from '../../constants/EmailPopups/HomePopupTriggerRules';
import HomePopupClients from '../../constants/EmailPopups/HomePopupClients';
import PopupEmailEditor from '../../constants/EmailPopups/PopupEmailEditor';
import SwiperSlider from '../../components/SwiperSlider';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';


import Bg from '../../images/background-1.png';
import SliderImg1 from '../../images/mike.png';
import SliderImg2 from '../../images/jockbrocas.png';
import SliderImg3 from '../../images/caseyjones.png';

const Title = () =>(
    <><h1><span className="style-highlight">Email Popups 
<svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg>
 </span> to Grow Your Marketing List and Revenue.</h1></>
);

const Slides = [
    {
        content: `
            <div className="swiper-slide-contents">
                <div className="elementor-slide-heading">"Was Not a fan of Popups, Until Now!"</div>
                <div className="elementor-slide-description">What I like here is, there is no subscriber limit and their templates are amazing. It also has a dashboard to monitor conversions/campaigns for all my website/clients!<br/><br/>
                <b>Mike</b> - mrdzyn.com 
                </div>
            </div>`,
        img: SliderImg1
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">"I Ditched Optinmonster&#8203;"</div><div className="elementor-slide-description">This has been the answer to my needs. I have jumped all in and now that I have played with it, I am slowly moving all my sites over to Optinly.<br><br><b>JockBrocas</b> - jockbrocas.com</div>
            </div>`,
        img: SliderImg2
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">Love Optinly!</div><div className="elementor-slide-description">One word pretty well sums it up - Awesome!<br><br>
            <b>Casey Jones</b> - www.cjco.com.au</div></div>`,
        img: SliderImg3
    },
]

const SwiperSlides = ()=>(
    <div className="position-relative">
        <SwiperSlider slidesPerView={1} slides={Slides} spaceBetween={0} slideDeley={5000} />
    </div>
);

const IndexPage = () => (
    <Layout className="landing-page">
        <Metatags
            title="Email Sign-Up Pop-ups | Optinly"
            description = "Increase lead generation with the email sign-up pop-ups. Optinly is a drag-and-drop pop-up builder that increases user engagement and conversion rates."
            keywords= {[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url = "https://www.optinly.com"
            pathname = "/"
        />
        <Banner title={<Title />} description="Create powerful email popups and convert website audience to email leads. Use Optinly’s pre-existing email popup templates to get started – it’s powerful, simple and all you’ve ever dreamt of!" actionUrl="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" actionText={"Create Your First Email Popup"} slider={<SwiperSlides />} bgImg={Bg} />
        
        <HomeIntegrationCompanies />
        <HomeCounter />
        <HomePopupFeatures />
        <HomePopupTypes />
        <HomePopupTriggerRules />
        <PopupEmailEditor />
        <HomePopupClients />
        <GetStarted/>
        <div className="py-5 faq-section">
            <div className="container py-3">
                <div className="mb-5 text-center hero-content wow fadeIn">
                    <h2>Email Popups <span className="style-highlight"> FAQ
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span>
                    </h2>
                </div>
                <Accordion allowZeroExpanded>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Are Email pop-ups effective for growing businesses?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Using popups on website is a great way to capture your visitor’s email address. Combining it with some attention-grabbing offers and welcome discounts can help you grow your email list with extreme ease. 
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Are Optinly’s email pop-up templates responsive?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Yes. Optinly’s pre-existing email collection popup templates are completely responsive. You can check the responsiveness of each email pop-up template when you customize your popups. 
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Can I display email collection popups on mobile devices and tablets?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Yes. With Optinly, you can display email popup on multiple devices including mobiles and tablets. You can toggle between device display rules to display email pop-up on all devices simultaneously or on select devices.  
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Will I be able to show email popups to visitors when they exit? 
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                You can reduce abandonment and capture the email addresses of visitors when they try to exit your webpage. Use the exit option to display email popups when visitors exit. 
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What is the maximum number of email popup campaigns I can create with Optinly?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                Optinly does not restrict you from creating email popup campaigns after a certain limit. You can create an unlimited number of campaigns – no strings attached.  
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What are some of the best email popup practices around?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                                When it comes to creating email popups, you need to make sure that you follow the best practices in order to create highly converting email popups. Here are some of the best email popup practices to grow your marketing list
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                What are some of the best email popup examples to be inspired from? 
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Email popups are now being used across different types of businesses and industries, thanks to their high conversion rates. Here are some of the <a href="https://optinly.com/blog/15-email-popup-examples-for-your-website/" target="_blank" rel="noopener">best email capture popup examples you should take a look at</a> before you create one for your website.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>                
            </div>
        </div>
        
    </Layout>
);

export default IndexPage;